import React from 'react';
import { Link } from 'react-router-dom';
import { Container, TextWrapper, Wrapper } from './style';
import Header from './shared/Header';
import RegisterForm from '../../components/RegisterForm';

function Login() {
  return (
    <Container>
      <Wrapper>
        <Header />
        <RegisterForm />
        <TextWrapper>
          Got an account?&nbsp;
          <Link to="/login">Sign in</Link>
        </TextWrapper>
      </Wrapper>
    </Container>
  );
}

export default Login;
