import styled from 'styled-components';
import { DARK } from '../../utils/colors';

export const Title = styled.h2`
  font-size: 18px;
  color: ${DARK};
  margin: 0;
`;

export const SubTitle = styled.h3`
  font-size: 16px;
  color: ${DARK};
  margin: 0;
`;

export const Paragraph = styled.p`
    font-size: 14px;
  color: ${DARK};
  margin: 0;
`;

export const Text = styled.span`
    font-size: 14px;
  color: ${DARK};
  margin: 0;
`;
