import React, { useCallback, useEffect, useState } from 'react';
import {
  Row, Col, Form, Alert,
} from 'antd';
import dayjs from 'dayjs';
import StyledInput from '../CustomeAntdComponents/StyledInput';
import { StyledButton } from '../CustomeAntdComponents/StyledButton';
import { getInitials, CreateReconciliationPayload } from './helper';
import StyledSelect from '../CustomeAntdComponents/StyledSelect';
import { useAppStore } from '../../store/global/appStore';
import { postAPI, putAPI } from '../../utils/api';
import { CREATE_RECONCILIATION_URL, HTTP_SUCCESS, UPDATE_RECONCILIATION_URL } from '../../utils/constants';
import StyledDatePicker from '../CustomeAntdComponents/StyledDatePicker';

type Props = {
  handleSubmit: () => void,
  closeDrawer: () => void,
  defaultData: Reconciliation | null,
}

function ReconciliationForm({ handleSubmit, closeDrawer, defaultData }: Props) {
  const { banks } = useAppStore();
  const [error, setError] = useState('');
  const [form] = Form.useForm();

  const claimPayment = useCallback(async (payload: CreateReconciliationPayload) => {
    const response = await postAPI(CREATE_RECONCILIATION_URL, payload);

    if (response.status === HTTP_SUCCESS) {
      form.resetFields();
      handleSubmit();
    } else {
      setError(response.data);
    }
  }, [form, handleSubmit]);

  const editClaimPayment = useCallback(async (payload: CreateReconciliationPayload) => {
    const response = await putAPI(`${UPDATE_RECONCILIATION_URL}/${defaultData?.id}`, payload);

    if (response.status === HTTP_SUCCESS) {
      form.resetFields();
      handleSubmit();
    } else {
      setError(response.data);
    }
  }, [form, handleSubmit, defaultData]);

  const onFinish = (values: CreateReconciliationPayload) => {
    const payload = {
      ...values,
      bankId: values.bank,
      fullName: values.customerName,
      paymentDate: dayjs(values.paymentDate).format('YYYY-MM-DD'),
    };

    setError('');

    if (defaultData) {
      editClaimPayment(payload);
    } else {
      claimPayment(payload);
    }
  };

  useEffect(() => {
    form.setFieldsValue(getInitials(defaultData));
  }, [form, defaultData]);

  return (
    <Form
      form={form}
      onFinish={onFinish}
    >

      <Row gutter={[8, 4]}>
        <Col span="24">
          <Form.Item
            name="customerName"
            rules={[{ required: true, message: 'Please enter customer name' }]}
          >
            <StyledInput placeholder="Customer Name" />
          </Form.Item>
        </Col>
        <Col span="24">
          <Form.Item
            name="bank"
            rules={[{ required: true, message: 'Please enter bank' }]}
          >
            <StyledSelect placeholder="Bank" options={banks} />
          </Form.Item>
        </Col>
        <Col span="24">
          <Form.Item
            name="amount"
            rules={[{ required: true, message: 'Please enter your last name' }]}
          >
            <StyledInput placeholder="Amount" />
          </Form.Item>
        </Col>
        <Col span="24">
          <Form.Item
            name="paymentDate"
            rules={[{ required: true, message: 'Please enter payment date' }]}
          >
            <StyledDatePicker placeholder="Payment Date" />
          </Form.Item>
        </Col>

        {error && (
          <Col span="24">
            <div style={{ marginBottom: '13px' }}>
              <Alert type="error" message={error} />
            </div>
          </Col>
        )}

        <Col span="24">
          <Row gutter={[16, 8]}>
            <Col span="12">
              <StyledButton mode="primary" htmlType="submit" block>
                Claim Payment
              </StyledButton>
            </Col>

            <Col span="12">
              <StyledButton mode="secondary" block onClick={closeDrawer}>
                Cancel
              </StyledButton>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
}

export default ReconciliationForm;
