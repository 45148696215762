import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ViewPageHeader from '../../components/PageHeader/viewPage';
import { getAPI } from '../../utils/api';
import { GET_PAYMENTS_URL, HTTP_SUCCESS } from '../../utils/constants';
import PaymentData from '../../ContentDetails/PaymentData';

function ViewPayment() {
  const { paymentId } = useParams();
  const [payment, setPayment] = useState<Payment | null>();

  const getPayment = useCallback(async () => {
    const paymentResponse = await getAPI(`${GET_PAYMENTS_URL}/${paymentId}`);

    if (paymentResponse.status === HTTP_SUCCESS) {
      setPayment(paymentResponse.data);
    } else {
      // setError(validateApi.data);
    }
  }, [paymentId]);

  useEffect(() => {
    getPayment();
  }, [getPayment]);

  return (
    <div>
      <ViewPageHeader title="Payment" />

      {payment && <PaymentData payment={payment} />}
    </div>
  );
}

export default ViewPayment;
