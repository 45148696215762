import { PAYMENT_STATUS, USER_ROLES } from './constants';

export const userDefaultRoute = (role: string) => {
  switch (role) {
    case USER_ROLES.ADMIN:
      return 'payment';
    case USER_ROLES.SALES:
      return 'reconciliation';
    default:
      return 'reconciliation';
  }
};

export const statusColor = (status: string) => {
  switch (status) {
    case PAYMENT_STATUS.RECEIVED:
      return 'geekblue';
    case PAYMENT_STATUS.NO_MATCH:
      return 'orange';
    case PAYMENT_STATUS.MATCHED:
      return 'green';
    case PAYMENT_STATUS.DUPLICATE:
      return 'red';
    default:
      return 'geekblue';
  }
};

export const getMenuItems = (role: string) => {
  const menuItems = [
    {
      key: 'reconciliation',
      label: 'Reconciliation',
    },
  ];

  if ([USER_ROLES.ADMIN, USER_ROLES.SUPER_ADMIN].includes(role)) {
    menuItems.push({
      key: 'payment',
      label: 'Payments',
    });
  }

  menuItems.push({
    key: 'login',
    label: 'Sign out',
  });

  return menuItems;
};

export const debounce = (callback: any, wait: number) => {
  let timeoutId: any;
  return (...args: any) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      // eslint-disable-next-line prefer-spread
      callback.apply(null, args);
    }, wait);
  };
};

export const statusAsText = (status: string) => status.replace('_', ' ').toUpperCase();
export const numberWithCommas = (x: number) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
