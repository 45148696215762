import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { useNavigationContext } from '../../contexts/navigation';
import { getCurrentUser } from '../../utils/api';
import { getMenuItems } from '../../utils/helper';

const {
  Header,
} = Layout;

function NavigationHeader() {
  const location = useLocation();
  const navigate = useNavigate();
  const { state: { activeMenu }, dispatch } = useNavigationContext();

  const handleMenuClick = (m: any) => {
    dispatch({
      activeMenu: m.key,
    });
    navigate(`/${m.key}`);
  };

  const currentUser = getCurrentUser();
  const menuItems = getMenuItems(currentUser?.role?.name);

  useEffect(() => {
    const menu = location.pathname.split('/')[1];
    dispatch({
      activeMenu: menu,
    });
  }, [dispatch, location.pathname]);

  return (
    <Header style={{
      position: 'sticky', top: 0, zIndex: 1, width: '100%',
    }}
    >
      <div
        style={{
          float: 'left',
          width: 40,
          height: 31,
          margin: '16px 24px 16px 0',
          background: 'rgba(255, 255, 255, 0.2)',
        }}
      />
      <Menu
        theme="dark"
        mode="horizontal"
        selectedKeys={[activeMenu]}
        items={menuItems}
        onClick={handleMenuClick}
      />
    </Header>
  );
}

export default NavigationHeader;
