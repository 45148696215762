import React, { useState, useCallback, useEffect } from 'react';
import { Drawer, Empty } from 'antd';
import PageHeader from '../../components/PageHeader';
import PaymentList from '../../components/TableList/PaymentList';
import PageSearch from '../../components/PageSearch';
import PaymentForm from '../../components/PaymentForm';
import { postAPI } from '../../utils/api';
import { GET_PAYMENTS_URL, HTTP_SUCCESS } from '../../utils/constants';
import Paginator from '../../components/Paginator';
import { MessageWrapper } from '../../utils/stylesheeet';

function Payment() {
  const [showDrawer, setShowDrawer] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [search, setSearch] = useState();
  const [filterDate, setFilterDate] = useState();
  const [payments, setPayments] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const getPayments = useCallback(async (params = {}) => {
    const paymentResponse = await postAPI(GET_PAYMENTS_URL, params);

    if (paymentResponse.status === HTTP_SUCCESS) {
      setPayments(paymentResponse.data.rows);
      setTotalCount(paymentResponse.data.count);
    } else {
      // setError(validateApi.data);
    }
  }, []);

  useEffect(() => {
    getPayments();
  }, [getPayments]);

  const closeDrawer = () => {
    setShowDrawer(false);
  };

  const handleShowDrawer = () => {
    setShowDrawer(true);
  };

  const handleSubmit = () => {
    closeDrawer();
    getPayments();
  };

  const handlePageData = (pageNumber: number, pageSize: number) => {
    getPayments({
      pageNumber, pageSize,
    });
    setPage(pageNumber);
    setSize(pageSize);
  };

  const handleFilter = (filter: any) => {
    getPayments({
      search: filter.search,
      paymentDate: filter.filterDate,
    });

    setSearch(filter.search);
    setFilterDate(filter.filterDate);
  };

  const handleRefetch = () => {
    getPayments({
      search,
      pageNumber: page,
      pageSize: size,
      paymentDate: filterDate,
    });
  };

  return (
    <div>
      <PageHeader title="Payments" showDrawer={handleShowDrawer} />
      <PageSearch handleFilter={handleFilter} />

      {totalCount === 0 ? (
        <MessageWrapper>
          <Empty />
        </MessageWrapper>
      ) : (
        <>
          <PaymentList records={payments} refetch={handleRefetch} />
          <Paginator totalCount={totalCount} getPageData={handlePageData} />
        </>
      )}

      <Drawer
        title="Add Payment"
        placement="right"
        closable={false}
        onClose={closeDrawer}
        open={showDrawer}
      >
        <PaymentForm handleSubmit={handleSubmit} closeDrawer={closeDrawer} />
      </Drawer>
    </div>
  );
}

export default Payment;
