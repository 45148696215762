import React, { ReactNode, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Layout } from 'antd';
import { BodyContent } from './styles';
import NavigationHeader from './NavigationHeader';
import {
  AUTH_KEY, GET_BANKS_URL, HTTP_SUCCESS,
} from '../../utils/constants';
import { useAppStore } from '../../store/global/appStore';
import { getAPI } from '../../utils/api';

type Props = {
    children: ReactNode
}

const { Footer } = Layout;

function AuthenticatedRoute({ children }: Props) {
  const navigate = useNavigate();
  const { setAppStoreData } = useAppStore();

  const getBanks = useCallback(async () => {
    const banksResponse = await getAPI(GET_BANKS_URL);

    if (banksResponse.status === HTTP_SUCCESS) {
      const bankOptions = banksResponse.data.map((bank: Bank) => ({
        label: bank.name,
        value: bank.id,
      }));
      setAppStoreData(bankOptions, 'banks');
    }
  }, [setAppStoreData]);

  useEffect(() => {
    const auth = localStorage.getItem(AUTH_KEY);

    if (!auth) {
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    getBanks();
  }, [getBanks]);

  return (
    <Layout>
      <Layout>
        <NavigationHeader />
        <BodyContent>
          {children}
        </BodyContent>
        <Footer style={{ textAlign: 'center' }}>Lexican Sales Reconciliation &copy; 2023</Footer>
      </Layout>
    </Layout>
  );
}

export default AuthenticatedRoute;
