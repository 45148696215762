import React from 'react';
import { Tag } from 'antd';
import dayJS from 'dayjs';
import { Link } from 'react-router-dom';
import {
  ContentCell, ContentHeader, ContentRow, ContentWrapper, FieldName, Wrapper,
} from './styles';
import { StyledButton } from '../CustomeAntdComponents/StyledButton';
import { SubTitle, Text } from '../Elements/typography';
import { numberWithCommas, statusAsText, statusColor } from '../../utils/helper';
import { getCurrentUser, putAPI } from '../../utils/api';
import {
  GET_RECONCILIATIONS_URL, HTTP_SUCCESS, PAYMENT_STATUS, USER_ROLES,
} from '../../utils/constants';
import useNotify from '../../hooks/useNotify';

type Props = {
  records: Reconciliation[],
  refetch: () => void,
  openEditForm: (arg: Reconciliation) => void
}

function ReconciliationList({ records, refetch, openEditForm }: Props) {
  const notify = useNotify();
  const currentUser = getCurrentUser();

  const handleMatchToPayment = async (id: string) => {
    const response = await putAPI(`${GET_RECONCILIATIONS_URL}/${id}/match`, {});
    if (response.status === HTTP_SUCCESS) {
      refetch();
    } else {
      notify(response.data);
    }
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <ContentHeader>
          <ContentCell>
            Reference
          </ContentCell>
          <ContentCell>
            Payment Date
          </ContentCell>
          <ContentCell>
            Customer Name
          </ContentCell>
          <ContentCell>
            Bank
          </ContentCell>
          <ContentCell>
            Amount
          </ContentCell>
          <ContentCell>
            Status
          </ContentCell>
          <ContentCell>
            Created Date
          </ContentCell>
          <ContentCell>
            Actions
          </ContentCell>
        </ContentHeader>

        {records.map((r) => (
          <ContentRow key={r.id}>
            <ContentCell>
              <FieldName>
                <SubTitle>
                  Reference
                </SubTitle>
              </FieldName>
              <Text>
                {r.reference.toUpperCase()}
              </Text>
            </ContentCell>
            <ContentCell>
              <FieldName>
                <SubTitle>
                  Payment Date
                </SubTitle>
              </FieldName>
              <Text>
                {dayJS(r.paymentDate).format('LL')}
              </Text>
            </ContentCell>
            <ContentCell>
              <FieldName>
                <SubTitle>
                  Customer Name
                </SubTitle>
              </FieldName>
              <Text>
                {r.fullName}
              </Text>
            </ContentCell>
            <ContentCell>
              <FieldName>
                <SubTitle>
                  Bank
                </SubTitle>
              </FieldName>
              <Text>
                {r.bank.name}
              </Text>
            </ContentCell>
            <ContentCell>
              <FieldName>
                <SubTitle>
                  Amount
                </SubTitle>
              </FieldName>
              <Text>
                {numberWithCommas(r.amount)}
              </Text>
            </ContentCell>
            <ContentCell>
              <FieldName>
                <SubTitle>
                  Status
                </SubTitle>
              </FieldName>
              <Tag color={statusColor(r.status)}>
                {statusAsText(r.status)}
              </Tag>
            </ContentCell>
            <ContentCell>
              <FieldName>
                <SubTitle>
                  Created Date
                </SubTitle>
              </FieldName>
              <Text>
                {dayJS(r.createdAt).format('LL')}
              </Text>
            </ContentCell>
            <ContentCell>
              <Link to={`/reconciliations/${r.id}`}>
                <StyledButton size="small" mode="primary">
                  View
                </StyledButton>
              </Link>
              &nbsp;&nbsp;
              {r.status === PAYMENT_STATUS.NO_MATCH && currentUser?.role?.name === USER_ROLES.SALES
                  && (
                  <>
                    <StyledButton size="small" mode="secondary" onClick={() => openEditForm(r)}>
                      Edit
                    </StyledButton>
                    &nbsp;&nbsp;
                  </>
                  )}
              {r.status === PAYMENT_STATUS.NO_MATCH
                  && (
                    <StyledButton size="small" mode="danger" onClick={() => handleMatchToPayment(r.id)}>
                      Match
                    </StyledButton>
                  )}
            </ContentCell>
          </ContentRow>
        ))}
      </ContentWrapper>
    </Wrapper>
  );
}

export default ReconciliationList;
