import styled from 'styled-components';
import device from '../../utils/mediaQuery';
import {
  GREY_1, GREY_15, GREY_6, WHITE,
} from '../../utils/colors';
import { SubTitle, Text } from '../Elements/typography';

export const Wrapper = styled.div`
  margin: 16px 0;
  
  @media ${device.large} {
    padding: 10px;
    background: ${WHITE};
    border-radius: 10px;
  }
`;

export const ContentWrapper = styled.div`
  display: block;
  margin-bottom: 10px;

  @media ${device.large} {
    display:  table;
    width: 100%;
    text-align: start;
    border-collapse: separate;
    border-spacing: 0 10px;
  }
`;

export const FieldName = styled.div`
  ${SubTitle} {
    color: ${GREY_15};
    font-weight: 400;
    font-size: 14px;
  }
  
  @media ${device.large} {
   display: none;
  }
`;

export const ContentCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;

  .ant-tag {
    &:last-of-type {
      margin-inline-end: 0;
    }
  }


  ${Text} {
    text-transform: capitalize;
  }
  
  @media ${device.large} {
    display:  table-cell;
    padding: 15px;
    margin-bottom: 0;
  }
`;

export const ContentHeader = styled.div`
  display: none;

  @media ${device.large} {
    display: table-header-group;
    font-weight: bold;
    background: ${GREY_1};
    border-radius: 10px;

    ${ContentCell} {
      padding: 20px;

      &:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      &:last-child {
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
      }
    }
  }
`;

export const ContentRow = styled.div`
  background: ${WHITE};
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 15px;

  @media ${device.large} {
    background: none;
    border: none;
    display:  table-row;
    width: auto;
    padding: 0;
    margin-bottom: 0;
        
    ${ContentCell} {
      border-top: 1px solid ${GREY_6};
      border-bottom: 1px solid ${GREY_6};
      
      &:first-child {
        border-left: solid 1px ${GREY_6};
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }
      
      &:last-child {
        border-right: solid 1px ${GREY_6};
        border-bottom-right-radius: 10px;
        border-top-right-radius: 10px;
      }
     }
  }
`;
