import React, { ReactNode, useState, useEffect } from 'react';
import { Select } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { Wrapper } from './styles';

type Props = {
  placeholder?: string,
  size?: SizeType,
  options: SelectOption[],
  onChange?: (value: any) => void;
  suffixIcon?: ReactNode,
  showSearch?: boolean
  filled?: boolean
  value?: string | number
}

const { Option } = Select;
function StyledSelect({
  size,
  options,
  placeholder,
  onChange,
  suffixIcon, showSearch, value,
  filled,
}:Props) {
  const [focus, setFocus] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string | number | undefined>(value);

  const hasValue = focus || (selectedValue) || (!selectedValue && selectedValue === 0);
  const labelClass = hasValue ? 'label as-label' : 'label as-placeholder';

  const handleChange = (input: string | number) => {
    setSelectedValue(input);
    if (onChange) { onChange(input); }
  };

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  return (
    <Wrapper
      filled={filled}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Select
        showSearch={showSearch}
        size={size}
        style={{ width: '100%' }}
        onChange={handleChange}
        suffixIcon={suffixIcon}
        value={value}
        filterOption={(input, option) => {
          if (option && option.value) {
            return String(option.children)
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
              || String(option.value)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
              || String(option.tags)
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0;
          }

          return false;
        }}
      >
        {
          options.map((option) => (
            <Option
              key={option.value}
              tags={option.tags}
              value={option.value}
              disabled={Boolean(option.disabled)}
            >
              {option.label}
            </Option>
          ))
        }
      </Select>
      <span className={labelClass}>
        {hasValue ? placeholder : placeholder}
      </span>
    </Wrapper>

  );
}

StyledSelect.defaultProps = {
  size: 'large',
  placeholder: undefined,
  suffixIcon: undefined,
  showSearch: false,
  value: undefined,
  onChange: undefined,
  filled: undefined,
};

export default StyledSelect;
