import create from 'zustand';

export const useAppStore = create<AppStoreState>((set) => ({
  // initial state
  banks: [],
  requesting: false,
  setRequesting: (requesting: boolean) => {
    set(() => ({
      requesting,
    }));
  },
  setAppStoreData: (data: any, key: string) => {
    set(() => ({
      [key]: data,
    }));
  },
}));
