import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Row, Col, Form, Alert,
} from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import StyledInput from '../CustomeAntdComponents/StyledInput';
import { StyledButton } from '../CustomeAntdComponents/StyledButton';
import StyledPasswordInput from '../CustomeAntdComponents/StyledInput/PasswordInput';
import { formInitialValues, validateConfirmPassword, validatePassword } from './helper';
import { postAPI } from '../../utils/api';
import { HTTP_SUCCESS, REGISTER_URL } from '../../utils/constants';
import { ErrorWrapper } from '../LoginForm/styles';

type RegistrationFormData = {
  firstName: string,
  lastName: string,
  email: string,
  password: string,
}

function RegisterForm() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onFinish = async (values: RegistrationFormData) => {
    setLoading(true);
    const response = await postAPI(REGISTER_URL, values);
    setLoading(false);

    if (response.status === HTTP_SUCCESS) {
      navigate('/login');
    } else {
      setError(response.data.message);
    }
  };

  return (
    <Form
      initialValues={formInitialValues}
      form={form}
      onFinish={onFinish}
    >

      {Boolean(error) && <ErrorWrapper><Alert message={error} type="error" /></ErrorWrapper>}

      <Row gutter={[0, 4]}>
        <Col span="24">
          <Form.Item
            name="firstName"
            rules={[{ required: true, message: 'Please enter your first name' }]}
          >
            <StyledInput placeholder="First Name" greyLabel />
          </Form.Item>
        </Col>
        <Col span="24">
          <Form.Item
            name="lastName"
            rules={[{ required: true, message: 'Please enter your last name' }]}
          >
            <StyledInput placeholder="Last Name" greyLabel />
          </Form.Item>
        </Col>
        <Col span="24">
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please enter a valid email address' }]}
          >
            <StyledInput placeholder="Email address" greyLabel />
          </Form.Item>
        </Col>
        <Col span="24">
          <Form.Item
            name="password"
            rules={[
              {
                validator: validatePassword,
              },
            ]}
          >
            <StyledPasswordInput placeholder="Password" greyLabel />
          </Form.Item>
        </Col>
        <Col span="24">
          <Form.Item
            name="confirmPassword"
            rules={[({ getFieldValue }) => ({
              validator(_, value) {
                return validateConfirmPassword(value, getFieldValue('password'));
              },
            }),
            ]}
          >
            <StyledPasswordInput placeholder="Re-type Password" greyLabel />
          </Form.Item>
        </Col>

        <Col span="24">
          <StyledButton mode="primary" htmlType="submit" block disabled={loading}>
            Sign up
            {' '}
            <ArrowRightOutlined />
          </StyledButton>
        </Col>
      </Row>
    </Form>
  );
}

export default RegisterForm;
