import React, {
  ReactNode, useState, ChangeEvent,
} from 'react';
import { Input } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { Wrapper } from './styles';

type Props = {
  showLabel?: boolean,
  placeholder?: string,
  onChange?: (value: string) => void;
  filled?: boolean;
  size?: SizeType,
  type?: string,
  value?: string,
  greyLabel?: boolean,
  suffix?: ReactNode,
}

function StyledInput({
  placeholder, size, filled, onChange, type, value, suffix, showLabel, greyLabel,

}: Props) {
  const [focus, setFocus] = useState(false);

  const hasValue = focus || (value && value.toString().length !== 0);
  const showLabelClass = showLabel ? 'label as-label' : 'label as-label hide-label';
  const labelClass = hasValue ? showLabelClass : 'label as-placeholder';

  const handleChange = (input: ChangeEvent<HTMLInputElement>) => {
    input.preventDefault();
    if (onChange) { onChange(input.target.value); }
  };

  return (
    <Wrapper
      filled={filled}
      greyLabel={greyLabel}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Input size={size} onChange={handleChange} value={value} type={type} suffix={suffix} />
      <span className={labelClass}>
        {hasValue ? placeholder : placeholder}
      </span>
    </Wrapper>
  );
}

StyledInput.defaultProps = {
  size: 'large',
  placeholder: undefined,
  onChange: undefined,
  filled: undefined,
  value: undefined,
  suffix: undefined,
  type: 'text',
  showLabel: true,
  greyLabel: false,
};
export default StyledInput;
