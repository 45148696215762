import React, { useState, ChangeEvent } from 'react';
import { Input } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Wrapper } from './styles';

type Props = {
  placeholder?: string,
  onChange?: (value: string) => void;
  filled?: boolean;
  greyLabel?: boolean;
  size?: SizeType
}
function StyledPasswordInput({
  placeholder, size, filled, greyLabel, onChange,
}: Props) {
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState('');

  const hasValue = focus || (value && value.length !== 0);
  const labelClass = hasValue ? 'label as-label' : 'label as-placeholder';

  const handleChange = (input: ChangeEvent<HTMLInputElement>) => {
    input.preventDefault();
    setValue(input.target.value);
    if (onChange) { onChange(input.target.value); }
  };

  const renderPasswordReveal = (visible: boolean) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />);

  return (
    <Wrapper
      filled={filled}
      greyLabel={greyLabel}
      onBlur={() => setFocus(false)}
      onFocus={() => setFocus(true)}
    >
      <Input.Password size={size} onChange={handleChange} defaultValue={value} iconRender={(visible) => renderPasswordReveal(visible)} />
      <span className={labelClass}>
        {hasValue ? placeholder : placeholder}
      </span>
    </Wrapper>
  );
}

StyledPasswordInput.defaultProps = {
  size: 'large',
  placeholder: undefined,
  onChange: undefined,
  filled: undefined,
  greyLabel: false,
};
export default StyledPasswordInput;
