import React from 'react';

function Home() {
  return (
    <div>
      Lorem ipsum dolor sit amet, consectetur adipisicing elit. Officia, rem.
    </div>
  );
}

export default Home;
