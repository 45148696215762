import styled, { css } from 'styled-components';
import { GREY_10, WHITE } from '../../../utils/colors';

export const Wrapper = styled.div<{ filled?: boolean}>`
  position: relative;

  .label {
    font-weight: normal;
    position: absolute;
    font-size: 13px;
    pointer-events: none;
    left: 12px;
    top: 16px;
    transition: 0.2s ease all;
    line-height: 13px;
  }

  .as-placeholder {
    color: gray;
  }

  .as-label {
    top: -8px;
    font-size: 11px !important;
    background: ${WHITE};
    padding: 0 4px;
    margin-left: -4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 98%;
    z-index: 2;
  }
  
  .ant-select {
    width: 100%;
  }
  
  .ant-select-selector {
    box-shadow: none !important;
  }
  
 .ant-select-focused .ant-select-selector {
    box-shadow: none !important;
 }
  .ant-select-selection-item {
    font-size: 13px;
  }

  ${({ filled }) => filled && css`
    .ant-select-selector {
      background: ${GREY_10} !important;
    }

    .as-label {
      background: #fff;
    }
  `}
`;
