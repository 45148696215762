import React, {
  useReducer, useContext, createContext, useMemo,
} from 'react';

export type NavigationState = {
    activeMenu: string,
};

export type NavigationContextType = {
    state: NavigationState,
    dispatch: (args: any) => void
};

const initialState = {
  activeMenu: '',
};

export const NavigationContext = createContext<NavigationContextType>({
  state: initialState,
  dispatch: () => ({}),
});

const reducer = (state: NavigationState, action: any) => {
  const payload = action || {};
  return { ...state, ...payload };
};

export const useNavigationContext = (): NavigationContextType => useContext(NavigationContext);

export function NavigationContextProvider({ children }: any) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const memoData = useMemo(() => ({ state, dispatch }), [state]);

  return (
    <NavigationContext.Provider value={memoData}>
      {children}
    </NavigationContext.Provider>
  );
}
export default NavigationContext;
