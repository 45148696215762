import React, { ReactNode } from 'react';

type Props = {
    children: ReactNode
}

function PublicRoute({ children }: Props) {
  return (
    <div>
      {children}
    </div>
  );
}

export default PublicRoute;
