import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PrinterOutlined } from '@ant-design/icons';
import { Wrapper, ActionWrapper } from './styles';
import { Title } from '../Elements/typography';
import { StyledButton } from '../CustomeAntdComponents/StyledButton';

type Props = {
    title: string,
}
function ViewPageHeader({ title }: Props) {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <Wrapper>
      <Title>
        {title}
      </Title>

      <ActionWrapper>
        <StyledButton mode="secondary" onClick={goBack}>
          <PrinterOutlined />
          Back
        </StyledButton>
        <StyledButton mode="primary">
          <PrinterOutlined />
          Print
        </StyledButton>
      </ActionWrapper>
    </Wrapper>
  );
}

export default ViewPageHeader;
