import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, TextWrapper, Wrapper } from './style';
import Header from './shared/Header';
import LoginForm from '../../components/LoginForm';
import { AUTH_KEY } from '../../utils/constants';

function Login() {
  useEffect(() => {
    localStorage.removeItem(AUTH_KEY);
  }, []);

  return (
    <Container>
      <Wrapper>
        <Header />
        <LoginForm />
        <TextWrapper>
          Don&#39;t have an account?&nbsp;
          <Link to="/register">Sign up now</Link>
        </TextWrapper>
      </Wrapper>
    </Container>
  );
}

export default Login;
