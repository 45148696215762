import React, { useCallback, useEffect, useState } from 'react';
import { Drawer, Empty } from 'antd';
import PageHeader from '../../components/PageHeader';
import ReconciliationList from '../../components/TableList/ReconciliationList';
import ReconciliationForm from '../../components/ReconciliationForm';
import { postAPI } from '../../utils/api';
import { GET_RECONCILIATIONS_URL, HTTP_SUCCESS } from '../../utils/constants';
import Paginator from '../../components/Paginator';
import { MessageWrapper } from '../../utils/stylesheeet';
import PageSearch from '../../components/PageSearch';

function Reconciliation() {
  const [showDrawer, setShowDrawer] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [search, setSearch] = useState();
  const [filterDate, setFilterDate] = useState();
  const [reconciliations, setReconciliations] = useState([]);
  const [reconciliation, setReconciliation] = useState<Reconciliation | null>(null);
  const [totalCount, setTotalCount] = useState(0);

  const getReconciliations = useCallback(async (params = {}) => {
    const response = await postAPI(GET_RECONCILIATIONS_URL, params);

    if (response.status === HTTP_SUCCESS) {
      setReconciliations(response.data.rows);
      setTotalCount(response.data.count);
    } else {
      // setError(validateApi.data);
    }
  }, []);

  useEffect(() => {
    getReconciliations();
  }, [getReconciliations]);

  const closeDrawer = () => {
    setShowDrawer(false);
    setReconciliation(null);
  };

  const handleShowDrawer = () => {
    setShowDrawer(true);
  };

  const handleSubmit = () => {
    closeDrawer();
    getReconciliations();
    setReconciliation(null);
  };

  const handlePageData = (pageNumber: number, pageSize: number) => {
    getReconciliations({
      pageNumber, pageSize,
    });
    setPage(pageNumber);
    setSize(pageSize);
  };

  const handleFilter = (filter: any) => {
    getReconciliations({
      search: filter.search,
      paymentDate: filter.filterDate,
    });
    setSearch(filter.search);
    setFilterDate(filter.filterDate);
  };

  const handleRefetch = () => {
    getReconciliations({
      search,
      pageNumber: page,
      pageSize: size,
      paymentDate: filterDate,
    });
  };

  const openEditForm = (data: Reconciliation) => {
    setShowDrawer(true);
    setReconciliation(data);
  };

  return (
    <div>
      <PageHeader title="Reconciliation" showDrawer={handleShowDrawer} />

      <PageSearch handleFilter={handleFilter} />

      {totalCount === 0 ? (
        <MessageWrapper>
          <Empty />
        </MessageWrapper>
      ) : (
        <>
          <ReconciliationList records={reconciliations} refetch={handleRefetch} openEditForm={openEditForm} />
          <Paginator totalCount={totalCount} getPageData={handlePageData} />
        </>
      )}

      <Drawer
        title="Claim Payment"
        placement="right"
        closable={false}
        onClose={closeDrawer}
        open={showDrawer}
      >
        <ReconciliationForm defaultData={reconciliation} handleSubmit={handleSubmit} closeDrawer={closeDrawer} />
      </Drawer>
    </div>
  );
}

export default Reconciliation;
