import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.less';
import dayJS from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import reportWebVitals from './reportWebVitals';
import AppRoute from './routing';
import { NavigationContextProvider } from './contexts/navigation';

// Initialized dayJS instance
dayJS.extend(localizedFormat);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <NavigationContextProvider>
    <AppRoute />
  </NavigationContextProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
