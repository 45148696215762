import styled from 'styled-components';
import { Layout } from 'antd';
import device from '../../utils/mediaQuery';

const { Content } = Layout;

export const BodyContent = styled(Content)`
   min-height: 50vh;
   margin: 0 auto;
   padding: 20px 0 0;
   width: 95%;

   @media ${device.large} {
      margin: 0 auto;
      width: 90%;
   }
  
   @media ${device.extraLarge} {
      margin: 0 auto;
      width: 85%;
   }
`;
