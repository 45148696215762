export const PRIMARY = '#223E7C';
export const RED = '#DC2626';
export const ORANGE = '#ff9900';
export const DARK_PRIMARY = '#001529';
export const WHITE = '#FFFFFF';
export const DARK = '#0E1723';
export const GREY = '#0E1723';
export const GREY_10 = '#f6f5f5';
export const GREY_6 = '#e0e0e0';
export const GREY_5 = '#F5F5F5';
export const GREY_15 = '#A4A4A4';
export const GREY_1 = '#F4F2F2';
