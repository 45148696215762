import axios from 'axios';
import { AUTH_KEY, BASE_API_URL } from './constants';

type APIErrorResponse = {
  message: string
}

const processAPIError = (e: any) => {
  let errorMessage: any = '';
  let errorStatus = 500;

  if (axios.isAxiosError(e) && e.response && e.response.data) {
    if (e.response) {
      // Response received from server
      errorStatus = e.response.status;
      const apiRe = e.response.data as APIErrorResponse;

      if (errorStatus === 500) {
        errorMessage = 'Sorry, we are unable to complete the request. Please try again';
      } else {
        errorMessage = apiRe.message;
      }
    } else if (e.request) {
      // No response received
      errorMessage = 'No response from server';
    } else {
      // Error in request setup
      errorMessage = 'Unable to process request';
    }
  }
  // App Error in request
  return { status: errorStatus, data: errorMessage };
};

const getRequestData = () => {
  const auth = JSON.parse(localStorage.getItem(AUTH_KEY) || '{}');

  const Authorization = `Bearer ${auth.accessToken}`;
  return { baseUrl: BASE_API_URL, Authorization };
};

export const getAPI = async (url: string) => {
  const { baseUrl, Authorization } = getRequestData();
  const resultingUrl = `${baseUrl}${url}`;

  try {
    const res = await axios.get(resultingUrl, {
      headers: {
        Authorization,
      },
    });

    return { status: 200, data: res.data };
  } catch (e: any) {
    return processAPIError(e);
  }
};

export const postAPI = async (url: string, payload: any) => {
  const { baseUrl, Authorization } = getRequestData();
  const resultingUrl = `${baseUrl}${url}`;

  try {
    const res = await axios.post(resultingUrl, payload, {
      headers: {
        Authorization,
      },
    });

    return { status: 200, data: res.data };
  } catch (e: any) {
    return processAPIError(e);
  }
};

export const putAPI = async (url: string, payload: any) => {
  const { baseUrl, Authorization } = getRequestData();
  const resultingUrl = `${baseUrl}${url}`;

  try {
    const res = await axios.put(resultingUrl, payload, {
      headers: {
        Authorization,
      },
    });

    return { status: 200, data: res.data };
  } catch (e: any) {
    return processAPIError(e);
  }
};

export const deleteAPI = async (url: string) => {
  const { baseUrl, Authorization } = getRequestData();
  const resultingUrl = `${baseUrl}${url}`;

  try {
    const res = await axios.delete(resultingUrl, {
      headers: {
        Authorization,
      },
    });

    return { status: 200, data: res.data };
  } catch (e: any) {
    return processAPIError(e);
  }
};

export const getCurrentUser = (): User => {
  const auth = JSON.parse(localStorage.getItem(AUTH_KEY) || '{}');

  return auth.user;
};
