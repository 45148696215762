import React from 'react';
import { PlusCircleOutlined, PrinterOutlined, ExportOutlined } from '@ant-design/icons';
import { ActionWrapper, Wrapper } from './styles';
import { Title } from '../Elements/typography';
import { StyledButton } from '../CustomeAntdComponents/StyledButton';

type Props = {
    title: string,
    showDrawer: () => void
}
function PageHeader({ title, showDrawer }: Props) {
  return (
    <Wrapper>
      <Title>
        {title}
      </Title>
      <ActionWrapper>
        <StyledButton mode="secondary">
          <PrinterOutlined />
          Print
        </StyledButton>
        <StyledButton mode="secondary">
          <ExportOutlined />
          Export
        </StyledButton>
        <StyledButton mode="primary" onClick={showDrawer}>
          <PlusCircleOutlined />
          Add Payment
        </StyledButton>
      </ActionWrapper>
    </Wrapper>
  );
}

export default PageHeader;
