import React, { useState, useEffect } from 'react';
import { Pagination } from 'antd';

type Props = {
    totalCount: number,
    getPageData: (pageNumber: number, pageSize: number) => void
}

function Paginator({ totalCount, getPageData }: Props) {
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);

  const handlePageChange = (pageNumber: number, pageSize: number) => {
    getPageData(pageNumber, pageSize);
    setPage(pageNumber);
    setSize(pageSize);
  };

  useEffect(() => {
    setPage(1);
    setSize(10);
  }, [totalCount]);

  return (
    <Pagination
      defaultCurrent={1}
      current={page}
      total={totalCount}
      pageSize={size}
      onChange={handlePageChange}
    />
  );
}

export default Paginator;
