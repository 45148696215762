import styled from 'styled-components';
import device from '../../utils/mediaQuery';

export const Wrapper = styled('div')`
  padding: 8px 0;
  border-bottom: 1px solid rgba(164, 164, 164, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;

export const ActionWrapper = styled('div')`
  display: flex;
  column-gap: 15px;
  
  span {
    &:last-of-type {
      display: none;
    }
  }
  
  @media ${device.large} {
    span {
      &:last-of-type {
        display: inline;
      }
    }
  }
`;
