import styled, { css } from 'styled-components';
import { Paragraph } from '../components/Elements/typography';

export const DetailTextItem = styled(Paragraph)< { alignRight?: boolean }>`
 margin: 15px;
  
 ${({ alignRight }) => alignRight && css`
    text-align: right;
 `}
`;

export const DetailItem = styled('div')< { alignRight?: boolean }>`
 margin: 15px;
  
 ${({ alignRight }) => alignRight && css`
    text-align: right;
 `}
`;
