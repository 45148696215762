import { notification } from 'antd';

const useNotify = () => {
  const showNotification = (message: string) => {
    notification.info({
      message,
      placement: 'bottom',
      duration: 0,
    });
  };

  return showNotification;
};

export default useNotify;
