import React from 'react';
import Login from './Authorization/Login';
import Register from './Authorization/Register';
import Home from './Home/index';
import Payment from './Payment/index';
import ViewPayment from './ViewPayment/index';
import Reconciliation from './Reconciliation/index';
import PublicRoute from '../components/Layouts/PublicRoute';
import AuthenticatedRoute from '../components/Layouts/AuthenticatedRoute';
import ViewReconciliation from './ViewReconciliation';

export function LoginScreen() {
  return (
    <PublicRoute>
      <Login />
    </PublicRoute>
  );
}

export function RegisterScreen() {
  return (
    <PublicRoute>
      <Register />
    </PublicRoute>
  );
}

export function HomeScreen() {
  return <AuthenticatedRoute><Home /></AuthenticatedRoute>;
}

export function PaymentScreen() {
  return <AuthenticatedRoute><Payment /></AuthenticatedRoute>;
}

export function ViewPaymentScreen() {
  return <AuthenticatedRoute><ViewPayment /></AuthenticatedRoute>;
}

export function ViewReconciliationScreen() {
  return <AuthenticatedRoute><ViewReconciliation /></AuthenticatedRoute>;
}

export function ReconciliationScreen() {
  return <AuthenticatedRoute><Reconciliation /></AuthenticatedRoute>;
}
