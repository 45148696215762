// API Routes
const {
  REACT_APP_BASE_API_URL,
} = process.env;

export const BASE_API_URL = REACT_APP_BASE_API_URL || 'http://localhost:8080/api/';

// API Paths
export const LOGIN_URL = 'auth/signin';
export const REGISTER_URL = 'auth/signup';
export const GET_PAYMENTS_URL = 'payments';
export const CREATE_PAYMENT_URL = 'payment';
export const MATCH_PAYMENT_URL = 'payment/match';
export const GET_BANKS_URL = 'banks';
export const GET_RECONCILIATIONS_URL = 'reconciliations';
export const CREATE_RECONCILIATION_URL = 'reconciliation';
export const UPDATE_RECONCILIATION_URL = 'reconciliations';

export const USER_ROLES = {
  ADMIN: 'admin',
  SALES: 'sales',
  SUPER_ADMIN: 'super_admin',
};

export const HTTP_UNAUTHORISED = 401;
export const HTTP_SUCCESS = 200;

export const AUTH_KEY = 'lexican-auth';

export const SIGN_IN_ROUTE = '/login';

export const PAYMENT_STATUS = {
  NO_MATCH: 'no_match',
  MATCHED: 'matched',
  RECEIVED: 'received',
  DUPLICATE: 'duplicate',
};
