import styled from 'styled-components';
import device from '../../utils/mediaQuery';
import { Paragraph, Title } from '../../components/Elements/typography';
import { GREY_15, PRIMARY } from '../../utils/colors';

export const Container = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled.div`
  width: 100%;
 
  @media ${device.medium} {
    width: 80%;
  }
 
  @media ${device.large} {
    width: 600px;
  }
`;

export const HeaderWrapper = styled.div`
  margin-bottom: 40px;
  
  ${Paragraph} {
    text-align: center;
    color: ${GREY_15};
  }
  
  ${Title} {
    text-align: center;
    margin-bottom: 10px;
  }
`;

export const TextWrapper = styled('p')`
  text-align: center;
   a {
     color: ${PRIMARY};
     text-decoration: none;
   }
`;
