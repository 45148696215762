import styled from 'styled-components';
import { PRIMARY } from '../../utils/colors';

export const LinkWrapper = styled.div`
  text-align: center;
  color: ${PRIMARY};
  margin-bottom: 15px;
`;

export const ErrorWrapper = styled.div`
  margin-bottom: 20px;
  
  .ant-alert-message {
    text-align: center;
  }
`;
