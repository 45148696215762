import { Button } from 'antd';
import styled, { css } from 'styled-components';
import {
  PRIMARY, GREY, RED, GREY_6, ORANGE,
} from '../../utils/colors';

type StyledButtonProp = {
  mode?: string
  padded?: string
  size?: string
  block?: boolean
}

export const StyledButton = styled(Button)<StyledButtonProp>`
  height: 45px;
  
  ${({ mode }) => mode === 'secondary' && css`
    color: ${GREY};
    background ${GREY_6};
    
    &:hover {
      color: ${GREY} !important;
      border-color: ${GREY_6} !important;
    }
  `}
  
  ${({ mode }) => mode === 'primary' && css`
    background: ${PRIMARY};
    border-color: ${PRIMARY};
    color: #fff;
    
    &:focus,
    &:hover {
      background: ${PRIMARY} !important;
      border-color: ${PRIMARY} !important;
      color: #fff !important;
    }
  `}
  
  ${({ mode }) => mode === 'danger' && css`
    background: ${RED};
    border-color: ${RED};
    color: #fff;
    
    &:focus,
    &:hover {
      background: ${RED} !important;
      border-color: ${RED} !important;
      color: #fff !important;
    }
  `}
  
  ${({ mode }) => mode === 'warning' && css`
    background: ${ORANGE};
    border-color: ${ORANGE};
    color: #fff;

    &:focus,
    &:hover {
      background: ${ORANGE} !important;
      border-color: ${ORANGE} !important;
      color: #fff !important;
    }
  `}
  
  ${({ padded }) => padded === 'large' && css`
    padding-left: 50px;
    padding-right: 50px;
  `}
  
  ${({ block }) => block && css`
   display: block;
  `}
`;
