import React from 'react';
import { Title, Paragraph } from '../../../components/Elements/typography';
import { HeaderWrapper } from '../style';

function Header() {
  return (
    <HeaderWrapper>
      <Title> Well, Hello there!</Title>
      <Paragraph> Welcome back to invoiced, we are happy you are here </Paragraph>
    </HeaderWrapper>
  );
}

export default Header;
