export const formInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  password: '',
  confirmPassword: '',
};

export const validatePassword = (_: any, value: string) => {
  if (!value) {
    return Promise.reject(new Error('Please enter your password'));
  }
  if (value.length < 8) {
    return Promise.reject(new Error('Password must be at least 8 characters long'));
  }

  return Promise.resolve();
};

export const validateConfirmPassword = (value: string, password: string) => {
  if (!value && password) {
    return Promise.reject(new Error('Please re-enter your password'));
  }

  if (password === value) {
    return Promise.resolve();
  }

  return Promise.reject(new Error('The two passwords that you entered do not match!'));
};
