import React, { useState, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import ViewPageHeader from '../../components/PageHeader/viewPage';
import { getAPI } from '../../utils/api';
import { GET_RECONCILIATIONS_URL, HTTP_SUCCESS } from '../../utils/constants';
import ReconciliationData from '../../ContentDetails/ReconciliationData';

function ViewReconciliation() {
  const { reconciliationId } = useParams();
  const [reconciliation, setReconciliation] = useState<Reconciliation | null>();

  const getReconciliation = useCallback(async () => {
    const reconciliationResponse = await getAPI(`${GET_RECONCILIATIONS_URL}/${reconciliationId}`);

    if (reconciliationResponse.status === HTTP_SUCCESS) {
      setReconciliation(reconciliationResponse.data);
    } else {
      // setError(validateApi.data);
    }
  }, [reconciliationId]);

  useEffect(() => {
    getReconciliation();
  }, [getReconciliation]);

  return (
    <div>
      <ViewPageHeader title="Reconciliation" />

      {reconciliation && <ReconciliationData reconciliation={reconciliation} />}
    </div>
  );
}

export default ViewReconciliation;
