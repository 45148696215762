import React, { useState, useMemo } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Dayjs } from 'dayjs';
import { InputWrapper, Wrapper } from './styles';
import StyledInput from '../CustomeAntdComponents/StyledInput';
import StyledDatePicker from '../CustomeAntdComponents/StyledDatePicker';
import { debounce } from '../../utils/helper';

type Props = {
    handleFilter: (arg: any) => void
}

function PageSearch({ handleFilter }: Props) {
  const [search, setSearch] = useState('');
  const [filterDate, setFilterDate] = useState<Dayjs | null>();

  const debounceSearch = useMemo(() => debounce((query: string) => {
    handleFilter({ search: query, filterDate });
  }, 400), [filterDate, handleFilter]);

  const handleSearch = (value: string) => {
    setSearch(value);
    const trimmedInput = value.trim();
    debounceSearch(trimmedInput);
  };

  const handleDateChange = (value: Dayjs | null) => {
    setFilterDate(value);
    handleFilter({ search, filterDate: value });
  };

  return (
    <Wrapper>
      <InputWrapper>
        <StyledInput onChange={handleSearch} showLabel={false} placeholder="Search" suffix={<SearchOutlined />} />
      </InputWrapper>

      <StyledDatePicker placeholder="Payment Date" onChange={handleDateChange} showLabel={false} />
    </Wrapper>
  );
}

export default PageSearch;
