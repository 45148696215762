import dayJS, { Dayjs } from 'dayjs';

export type CreateReconciliationPayload = {
  customerName: string,
  amount: string | number,
  bank: string,
  paymentDate: string | Dayjs,
}

export const formInitialValues: CreateReconciliationPayload = {
  customerName: '',
  amount: '',
  bank: '',
  paymentDate: '',
};

export const getInitials = (defaultData: Reconciliation | null): CreateReconciliationPayload => {
  if (defaultData) {
    return {
      customerName: defaultData.fullName,
      amount: defaultData.amount,
      bank: defaultData.bankId,
      paymentDate: dayJS(defaultData.paymentDate),
    };
  }
  return formInitialValues;
};
