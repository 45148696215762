import React from 'react';
import { Col, Row, Tag } from 'antd';
import dayJS from 'dayjs';
import { DetailTextItem, DetailItem } from './styles';
import { numberWithCommas, statusAsText, statusColor } from '../utils/helper';

type Props = {
    payment: Payment
}
function PaymentData({ payment }: Props) {
  return (
    <>
      <Row>
        <Col span={12}>
          <DetailTextItem alignRight>
            Payment Date
          </DetailTextItem>
        </Col>
        <Col span={12}>
          <DetailTextItem>
            {dayJS(payment.paymentDate).format('LL')}
          </DetailTextItem>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <DetailTextItem alignRight>
            Customer Name
          </DetailTextItem>
        </Col>
        <Col span={12}>
          <DetailTextItem>
            {payment.fullName}
          </DetailTextItem>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <DetailTextItem alignRight>
            Amount
          </DetailTextItem>
        </Col>
        <Col span={12}>
          <DetailTextItem>
            {numberWithCommas(payment.amount)}
          </DetailTextItem>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <DetailTextItem alignRight>
            Bank
          </DetailTextItem>
        </Col>
        <Col span={12}>
          <DetailTextItem>
            {payment.bank.name}
          </DetailTextItem>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <DetailTextItem alignRight>
            Status
          </DetailTextItem>
        </Col>
        <Col span={12}>
          <DetailItem>
            <Tag color={statusColor(payment.status)}>
              {statusAsText(payment.status)}
            </Tag>
          </DetailItem>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <DetailTextItem alignRight>
            Created By
          </DetailTextItem>
        </Col>
        <Col span={12}>
          <DetailTextItem>
            {`${payment.createdBy.firstName} ${payment.createdBy.lastName}`}
          </DetailTextItem>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <DetailTextItem alignRight>
            Created Date
          </DetailTextItem>
        </Col>
        <Col span={12}>
          <DetailTextItem>
            {dayJS(payment.createdAt).format('LL')}
          </DetailTextItem>
        </Col>
      </Row>
    </>

  );
}

export default PaymentData;
