import React, { useCallback, useState } from 'react';
import dayJS from 'dayjs';
import {
  Row, Col, Form, Alert, Space,
} from 'antd';
import StyledInput from '../CustomeAntdComponents/StyledInput';
import { StyledButton } from '../CustomeAntdComponents/StyledButton';
import { formInitialValues } from './helper';
import { postAPI } from '../../utils/api';
import { MATCH_PAYMENT_URL, HTTP_SUCCESS } from '../../utils/constants';
import { SubTitle } from '../Elements/typography';
import { DetailTextItem } from '../../ContentDetails/styles';
import { numberWithCommas } from '../../utils/helper';
import { MatchPaymentDetail } from './styles';

type Props = {
  onMatch: () => void,
  closeDrawer: () => void,
  // eslint-disable-next-line react/require-default-props
  payment?: Payment
}
function MatchPaymentForm({ onMatch, payment, closeDrawer }: Props) {
  const [error, setError] = useState('');
  const [form] = Form.useForm();

  const matchPayment = useCallback(async (payload: MatchPaymentPayload) => {
    const paymentResponse = await postAPI(`${MATCH_PAYMENT_URL}`, payload);

    if (paymentResponse.status === HTTP_SUCCESS) {
      form.resetFields();
      onMatch();
    } else {
      setError(paymentResponse.data);
    }
  }, [form, onMatch]);

  const onFinish = (values: MatchPaymentPayload) => {
    const payload = {
      ...values,
      paymentId: payment?.id || '',
    };

    setError('');
    matchPayment(payload);
  };

  const onCancel = () => {
    closeDrawer();
    form.resetFields();
  };

  return (
    <>
      {
            payment && (
            <MatchPaymentDetail>
              <Space direction="vertical" size="large">
                <SubTitle className="pb-2">
                  You are about to match a payment to a reconciliation.
                </SubTitle>
                <div>
                  <Row>
                    <Col span={12}>
                      <DetailTextItem alignRight>
                        Payment Date
                      </DetailTextItem>
                    </Col>
                    <Col span={12}>
                      <DetailTextItem>
                        {dayJS(payment.paymentDate).format('LL')}
                      </DetailTextItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <DetailTextItem alignRight>
                        Customer Name
                      </DetailTextItem>
                    </Col>
                    <Col span={12}>
                      <DetailTextItem>
                        {payment.fullName}
                      </DetailTextItem>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={12}>
                      <DetailTextItem alignRight>
                        Amount
                      </DetailTextItem>
                    </Col>
                    <Col span={12}>
                      <DetailTextItem>
                        {numberWithCommas(payment.amount)}
                      </DetailTextItem>
                    </Col>
                  </Row>
                </div>
              </Space>
            </MatchPaymentDetail>
            )
        }
      <Form
        initialValues={formInitialValues}
        form={form}
        onFinish={onFinish}
      >
        <Row gutter={16}>
          <Col span="24" />
          <Col span="24">
            <Form.Item
              name="reference"
              rules={[{ required: true, message: 'Please enter payment reference number' }]}
            >
              <StyledInput placeholder="Reconciliation Reference" />
            </Form.Item>
          </Col>

          {error && (
            <Col span="24">
              <div style={{ marginBottom: '13px' }}>
                <Alert type="error" message={error} />
              </div>
            </Col>
          )}

          <Col span="24">
            <Row gutter={[16, 8]}>
              <Col span="12">
                <StyledButton mode="primary" htmlType="submit" block>
                  Match Payment
                </StyledButton>
              </Col>

              <Col span="12">
                <StyledButton mode="secondary" block onClick={onCancel}>
                  Cancel
                </StyledButton>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default MatchPaymentForm;
