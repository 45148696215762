import styled from 'styled-components';
import { PRIMARY, WHITE } from '../../../utils/colors';

export const Wrapper = styled.div`
  position: relative;

  .label {
    font-weight: normal;
    position: absolute;
    font-size: 13px;
    pointer-events: none;
    left: 12px;
    top: 16px;
    transition: 0.2s ease all;
    line-height: 13px;
  }

  .as-label.hide-label {
    display: none;
  }

  .as-placeholder {
    color: gray;
  }

  .as-label {
    top: -8px;
    font-size: 11px !important;
    background: ${WHITE};
    padding: 0 4px;
    margin-left: -4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 98%;
    z-index: 2;
  }
  
  .ant-picker {
    width: 100%;
    padding: 3px 11px 11px;
    
    input {
      font-size: 16px;
      margin-top: 10px;
    }
  }

  .ant-picker-suffix {
    margin-top: 12px;
  }

  .ant-picker-focused {
    box-shadow: none !important;
  }

  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
  .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner
  {
    // background: ${PRIMARY};
  }

  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border: solid 1px ${PRIMARY};
  }
`;
