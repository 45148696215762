import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Row, Col, Form, Alert,
} from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';
import StyledInput from '../CustomeAntdComponents/StyledInput';
import { StyledButton } from '../CustomeAntdComponents/StyledButton';
import { ErrorWrapper, LinkWrapper } from './styles';
import StyledPasswordInput from '../CustomeAntdComponents/StyledInput/PasswordInput';
import { postAPI } from '../../utils/api';
import { AUTH_KEY, HTTP_SUCCESS, LOGIN_URL } from '../../utils/constants';
import { userDefaultRoute } from '../../utils/helper';
import { useAppStore } from '../../store/global/appStore';

type LoginFormData = {
  email: string,
  password: string
}
function LoginForm() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { setAppStoreData } = useAppStore();

  const formInitialValues: LoginFormData = {
    email: '',
    password: '',
  };

  const onFinish = async (values: LoginFormData) => {
    setLoading(true);
    const loginUser = await postAPI(LOGIN_URL, {
      email: values.email,
      password: values.password,
    });

    setLoading(false);

    if (loginUser.status === HTTP_SUCCESS) {
      setAppStoreData(loginUser.data.user, 'user');
      localStorage.setItem(AUTH_KEY, JSON.stringify(loginUser.data));
      navigate(`/${userDefaultRoute(loginUser.data.user.role.name)}`);
    } else {
      setError(loginUser.data);
    }
  };

  return (
    <Form
      initialValues={formInitialValues}
      form={form}
      onFinish={onFinish}
    >

      {Boolean(error) && <ErrorWrapper><Alert message={error} type="error" /></ErrorWrapper>}

      <Row gutter={[0, 0]}>
        <Col span="24">
          <Form.Item
            name="email"
            rules={[{ required: true, message: 'Please enter a valid email address' }]}
          >
            <StyledInput placeholder="Email address" greyLabel />
          </Form.Item>

        </Col>
        <Col span="24">
          <Form.Item
            name="password"
            rules={[{ required: true, message: 'Please enter your password' }]}
          >
            <StyledPasswordInput placeholder="Password" greyLabel />
          </Form.Item>
        </Col>
        <Col span="24">
          <LinkWrapper>
            Forgot password?
          </LinkWrapper>
        </Col>
        <Col span="24">
          <StyledButton mode="primary" htmlType="submit" block disabled={loading}>
            Sign in
            {' '}
            <ArrowRightOutlined />
          </StyledButton>
        </Col>
      </Row>
    </Form>
  );
}

export default LoginForm;
