import styled from 'styled-components';
import { GREY_1 } from '../../utils/colors';
import device, { maxWidthQuery } from '../../utils/mediaQuery';
import { Wrapper as DatePickerWrapper } from '../CustomeAntdComponents/StyledDatePicker/styles';

export const Wrapper = styled('div')`
  padding: 15px 0 8px;
  align-items: center;
  column-gap: 20px;
  
  ${DatePickerWrapper} {
    .ant-picker {
      background: ${GREY_1};
      padding: 0 11px 6px;
      
      input {
        font-size: 15px;
      }
    }

    .ant-picker-suffix {
      margin-top: 8px;
    };

    @media ${maxWidthQuery.medium} {
     margin: 10px 0;
    }
  }

  @media ${device.large} {
    display: flex;
  }

`;

export const InputWrapper = styled('div')`
  .ant-input,
  .ant-input-affix-wrapper {
    background: ${GREY_1};
  }

  @media ${device.medium} {
    width: 60%;
  }

  @media ${device.large} {
    width: 50%;
  }

  @media ${device.extraLarge} {
    width: 30%;
  }
`;

export const ActionWrapper = styled('div')`
  display: flex;
  column-gap: 15px;
`;
