import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import dayJS from 'dayjs';
import { Drawer, Tag } from 'antd';
import {
  ContentCell, ContentHeader, ContentRow, ContentWrapper, FieldName, Wrapper,
} from './styles';
import { StyledButton } from '../CustomeAntdComponents/StyledButton';
import { SubTitle, Text } from '../Elements/typography';
import { numberWithCommas, statusAsText, statusColor } from '../../utils/helper';
import { GET_PAYMENTS_URL, HTTP_SUCCESS, PAYMENT_STATUS } from '../../utils/constants';
import { deleteAPI } from '../../utils/api';
import MatchPaymentForm from '../MatchPaymentForm';

type Props = {
  records: Payment[],
  refetch: () => void
}
function PaymentList({ records, refetch }: Props) {
  const [showDrawer, setShowDrawer] = useState(false);
  const [activePayment, setActivePayment] = useState<Payment | undefined>();
  const handleDelete = async (id: string) => {
    const paymentResponse = await deleteAPI(`${GET_PAYMENTS_URL}/${id}`);
    if (paymentResponse.status === HTTP_SUCCESS) {
      refetch();
    }
  };

  const closeDrawer = () => {
    setShowDrawer(false);
  };

  const handleShowDrawer = (payment: Payment) => {
    setShowDrawer(true);
    setActivePayment(payment);
  };

  const handleMatchToReconciliation = async () => {
    setActivePayment(undefined);
    setShowDrawer(false);
    refetch();
  };

  return (
    <Wrapper>
      <ContentWrapper>
        <ContentHeader>
          <ContentCell>
            Payment Date
          </ContentCell>
          <ContentCell>
            Customer Name
          </ContentCell>
          <ContentCell>
            Bank
          </ContentCell>
          <ContentCell>
            Amount
          </ContentCell>
          <ContentCell>
            Created Date
          </ContentCell>
          <ContentCell>
            Status
          </ContentCell>
          <ContentCell>
            Actions
          </ContentCell>
        </ContentHeader>

        {records.map((d) => (
          <ContentRow key={d.id}>
            <ContentCell>
              <FieldName>
                <SubTitle>
                  Payment Date
                </SubTitle>
              </FieldName>
              <Text>
                {dayJS(d.paymentDate).format('LL')}
              </Text>
            </ContentCell>
            <ContentCell>
              <FieldName>
                <SubTitle>
                  Customer Name
                </SubTitle>
              </FieldName>
              <Text>
                {d.fullName}
              </Text>
            </ContentCell>
            <ContentCell>
              <FieldName>
                <SubTitle>
                  Bank
                </SubTitle>
              </FieldName>
              <Text>
                {d.bank.name}
              </Text>
            </ContentCell>
            <ContentCell>
              <FieldName>
                <SubTitle>
                  Amount
                </SubTitle>
              </FieldName>
              <Text>
                {numberWithCommas(d.amount)}
              </Text>
            </ContentCell>
            <ContentCell>
              <FieldName>
                <SubTitle>
                  Created Date
                </SubTitle>
              </FieldName>
              <Text>
                {dayJS(d.createdAt).format('LL')}
              </Text>
            </ContentCell>
            <ContentCell>
              <FieldName>
                <SubTitle>
                  Status
                </SubTitle>
              </FieldName>
              <Tag color={statusColor(d.status)}>
                {statusAsText(d.status)}
              </Tag>
            </ContentCell>
            <ContentCell>
              <Link to={`/payments/${d.id}`}>
                <StyledButton size="small" mode="primary">
                  View
                </StyledButton>
              </Link>
              {d.status === PAYMENT_STATUS.RECEIVED && (
              <>
                    &nbsp;&nbsp;
                <StyledButton size="small" mode="warning" onClick={() => handleShowDrawer(d)}>
                  Match
                </StyledButton>
              </>
              )}
              {d.status !== PAYMENT_STATUS.MATCHED && (
              <>
                    &nbsp;&nbsp;
                <StyledButton size="small" mode="danger" onClick={() => handleDelete(d.id)}>
                  Delete
                </StyledButton>
              </>
              )}
            </ContentCell>
          </ContentRow>
        ))}
      </ContentWrapper>

      <Drawer
        title="Match Payment"
        placement="right"
        closable={false}
        onClose={closeDrawer}
        open={showDrawer}
      >
        <MatchPaymentForm
          payment={activePayment}
          onMatch={handleMatchToReconciliation}
          closeDrawer={closeDrawer}
        />
      </Drawer>
    </Wrapper>
  );
}

export default PaymentList;
