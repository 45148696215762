import React, { useCallback, useState } from 'react';
import dayjs from 'dayjs';
import {
  Row, Col, Form, Alert,
} from 'antd';
import StyledInput from '../CustomeAntdComponents/StyledInput';
import { StyledButton } from '../CustomeAntdComponents/StyledButton';
import { formInitialValues } from './helper';
import StyledSelect from '../CustomeAntdComponents/StyledSelect';
import { useAppStore } from '../../store/global/appStore';
import StyledDatePicker from '../CustomeAntdComponents/StyledDatePicker';
import { postAPI } from '../../utils/api';
import { CREATE_PAYMENT_URL, HTTP_SUCCESS } from '../../utils/constants';

type Props = {
  handleSubmit: () => void,
  closeDrawer: () => void,
}
function PaymentForm({ handleSubmit, closeDrawer }: Props) {
  const { banks } = useAppStore();
  const [error, setError] = useState('');
  const [form] = Form.useForm();

  const createPayment = useCallback(async (payload: CreatePaymentPayload) => {
    const paymentResponse = await postAPI(CREATE_PAYMENT_URL, payload);

    if (paymentResponse.status === HTTP_SUCCESS) {
      form.resetFields();
      handleSubmit();
    } else {
      setError(paymentResponse.data);
    }
  }, [form, handleSubmit]);

  const onFinish = (values: CreatePaymentPayload) => {
    const payload = {
      ...values,
      bankId: values.bank,
      fullName: values.customerName,
      paymentDate: dayjs(values.paymentDate).format('YYYY-MM-DD'),
    };

    setError('');
    createPayment(payload);
  };

  const onCancel = () => {
    closeDrawer();
    form.resetFields();
  };

  return (
    <Form
      initialValues={formInitialValues}
      form={form}
      onFinish={onFinish}
    >

      <Row gutter={16}>
        <Col span="24">
          <Form.Item
            name="customerName"
            rules={[{ required: true, message: 'Please enter customer name' }]}
          >
            <StyledInput placeholder="Customer Name" />
          </Form.Item>
        </Col>
        <Col span="24">
          <Form.Item
            name="bank"
            rules={[{ required: true, message: 'Please enter bank' }]}
          >
            <StyledSelect placeholder="Bank" options={banks} />
          </Form.Item>
        </Col>
        <Col span="24">
          <Form.Item
            name="amount"
            rules={[{ required: true, message: 'Please enter your last name' }]}
          >
            <StyledInput placeholder="Amount" type="number" />
          </Form.Item>
        </Col>

        <Col span="24">
          <Form.Item
            name="paymentDate"
            rules={[{ required: true, message: 'Please enter payment date' }]}
          >
            <StyledDatePicker placeholder="Payment Date" />
          </Form.Item>
        </Col>

        {error && (
        <Col span="24">
          <div style={{ marginBottom: '13px' }}>
            <Alert type="error" message={error} />
          </div>
        </Col>
        )}

        <Col span="24">
          <Row gutter={[16, 8]}>
            <Col span="12">
              <StyledButton mode="primary" htmlType="submit" block>
                Add Payment
              </StyledButton>
            </Col>

            <Col span="12">
              <StyledButton mode="secondary" block onClick={onCancel}>
                Cancel
              </StyledButton>
            </Col>
          </Row>

        </Col>
      </Row>
    </Form>
  );
}

export default PaymentForm;
