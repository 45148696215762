import React from 'react';
import { Col, Row, Tag } from 'antd';
import dayJS from 'dayjs';
import { DetailTextItem, DetailItem } from './styles';
import { statusAsText, statusColor } from '../utils/helper';

type Props = {
    reconciliation: Reconciliation
}
function ReconciliationData({ reconciliation }: Props) {
  return (
    <>
      <Row>
        <Col span={12}>
          <DetailTextItem alignRight>
            Payment Date
          </DetailTextItem>
        </Col>
        <Col span={12}>
          <DetailTextItem>
            {dayJS(reconciliation.paymentDate).format('LL')}
          </DetailTextItem>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <DetailTextItem alignRight>
            Customer Name
          </DetailTextItem>
        </Col>
        <Col span={12}>
          <DetailTextItem>
            {reconciliation.fullName}
          </DetailTextItem>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <DetailTextItem alignRight>
            Amount
          </DetailTextItem>
        </Col>
        <Col span={12}>
          <DetailTextItem>
            {reconciliation.amount}
          </DetailTextItem>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <DetailTextItem alignRight>
            Bank
          </DetailTextItem>
        </Col>
        <Col span={12}>
          <DetailTextItem>
            {reconciliation.bank.name}
          </DetailTextItem>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <DetailTextItem alignRight>
            Status
          </DetailTextItem>
        </Col>
        <Col span={12}>
          <DetailItem>
            <Tag color={statusColor(reconciliation.status)}>
              {statusAsText(reconciliation.status)}
            </Tag>
          </DetailItem>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <DetailTextItem alignRight>
            Created By
          </DetailTextItem>
        </Col>
        <Col span={12}>
          <DetailTextItem>
            {`${reconciliation.createdBy.firstName} ${reconciliation.createdBy.lastName}`}
          </DetailTextItem>
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <DetailTextItem alignRight>
            Created Date
          </DetailTextItem>
        </Col>
        <Col span={12}>
          <DetailTextItem>
            {dayJS(reconciliation.createdAt).format('LL')}
          </DetailTextItem>
        </Col>
      </Row>
    </>

  );
}

export default ReconciliationData;
