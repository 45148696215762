const size = {
  extraSmall: '576px',
  small: '576px',
  medium: '768px',
  large: '992px',
  extraLarge: '1200px',
  extraLargePlus: '1600px',
};

const maxSize = {
  extraSmall: '576px',
  small: '767px',
  medium: '991px',
  large: '1199px',
  extraLarge: '1599px',
  extraLargePlus: '1600px',
};

const query = {
  extraSmall: `(max-width: ${size.extraSmall})`,
  small: `(min-width: ${size.small})`,
  medium: `(min-width: ${size.medium})`,
  large: `(min-width: ${size.large})`,
  extraLarge: `(min-width: ${size.extraLarge})`,
  extraLargePlus: `(min-width: ${size.extraLargePlus})`,
};

export const maxWidthQuery = {
  extraSmall: `(max-width: ${maxSize.extraSmall})`,
  small: `(max-width: ${maxSize.small})`,
  medium: `(max-width: ${maxSize.medium})`,
  large: `(max-width: ${maxSize.large})`,
  extraLarge: `(max-width: ${maxSize.extraLarge})`,
  extraLargePlus: `(min-width: ${maxSize.extraLargePlus})`,
};

export default query;
