import styled from 'styled-components';
import { PRIMARY } from '../../utils/colors';
import { DetailTextItem } from '../../ContentDetails/styles';

export const MatchPaymentDetail = styled.div`
 ${DetailTextItem} {
    margin: 5px;
 }
 margin-bottom: 10px;
`;

export const LinkWrapper = styled.div`
  text-align: center;
  color: ${PRIMARY};
  margin-bottom: 15px;
`;
