import React from 'react';
import {
  HomeScreen,
  LoginScreen, RegisterScreen, ReconciliationScreen, PaymentScreen, ViewPaymentScreen, ViewReconciliationScreen,
} from '../screens';
import { Route } from './type';

const routes: Route[] = [
  {
    id: '1',
    path: '/',
    element: <HomeScreen />,
  },
  {
    id: '2',
    path: '/login',
    element: <LoginScreen />,
  },
  {
    id: '2',
    path: '/register',
    element: <RegisterScreen />,
  },
  {
    id: '3',
    path: '/payment',
    element: <PaymentScreen />,
  },
  {
    id: '4',
    path: '/payments/:paymentId',
    element: <ViewPaymentScreen />,
  },
  {
    id: '5',
    path: '/reconciliation',
    element: <ReconciliationScreen />,
  },
  {
    id: '6',
    path: '/reconciliations/:reconciliationId',
    element: <ViewReconciliationScreen />,
  },
];

export default routes;
